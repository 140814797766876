import React, { useState } from 'react'
// import timeAgo from 'nta';
import { Edit2, Trash } from 'react-feather';
// import DeleteTopicModal from './DeleteTopicModal';
import EditCategoryModal from './EditCategoryModal';
import resolveImg from '../../helpers/resolveImg';


export default function CategoriesTableRow({category}) {

   let [isEditModalOpen, setIsEditModalOpen] = useState(false);
   // let [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

   return (
      <tr>
         <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            <img src={resolveImg(category.img)} className='w-16 h-16' />
         </td>
         <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            {category.name}
         </td>

         <td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
            <span aria-label='edit-topic' className='inline-block bg-blue-100 p-2 cursor-pointer hover:bg-blue-200 rounded-full ml-3' onClick={() => setIsEditModalOpen(true)}> <Edit2 size={18} className='text-blue-500' /> </span>
            
            {/* Modals */}
            <EditCategoryModal
               isOpen={isEditModalOpen}
               closeModal={() => setIsEditModalOpen(false)}
               category={category}
            />

         </td>
      </tr>
   )
}
