import React, { useState } from 'react';
import Modal from './../common/Modal';
import { useFormik } from 'formik';
import axios from './../../config/axios';
import Cookies from 'js-cookie';

export default function DeleteQuestionModal({ question, isOpen, closeModal }) {
   let [isSending, setIsSending] = useState(false);

   let formik = useFormik({
      initialValues: {},

      onSubmit: async () => {
         setIsSending(true);
         try {
            let res = await axios.post('/question/delete', {
               id: question.id
            });
            if (res.status === 200) {
               Cookies.set('success', res.data, {
                  expires: new Date(new Date().getTime() + 15 * 1000)
               });

               window.location.reload();
            }
         } catch (err) {
            alert('Something went wrong, please refresh the browser and try again');
         }

         setIsSending(false);
      }
   });

   return (
      <Modal title="حذف السؤال" isOpen={isOpen} onClose={closeModal}>
         <p className='whitespace-pre-wrap'>
            هل تريد بالتأكيد حذف هذا السؤال <b className='whitespace-pre-wrap'> "{question.title}" </b>
			</p>
         <form onSubmit={formik.handleSubmit}>
            <div>
               {isSending ? (
                  <button
                     type="button"
                     className="m-2 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded mt-5"
                  >
                     جارٍ الحذف ...
						</button>
               ) : (
                     <button
                        type="submit"
                        className="m-2 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded mt-5"
                     >
                        حذف
						</button>
                  )}
               <button
                  onClick={closeModal}
                  type="button"
                  className="m-2 inline-block shadow bg-gray-300 p-2 px-3 rounded text-gray-600 font-semibold focus:outline-none hover:bg-gray-400"
               >
                  إلغاء
					</button>
            </div>
         </form>
      </Modal>
   );
}
