import axios from '../../config/axios'
import { toast } from 'react-toastify'


export default async function updateCategory(data){
   try{
      // console.log(data)
      await axios.post('/category/edit/', data);

      toast("تم تحديث التخصص بنجاح", { className: "successToast" });

      // setTimeout(() => {
        window.location.reload();
      // }, 2000)

   }catch(err){
      if (err.response) {
         if (err.response.status === 400) {
           toast(err.response.data[0], { className: "errorToast" });
         }
       } else {
         toast("حدث خطأ ما ، يرجى إعادة المحاولة لاحقًا", {
           className: "errorToast",
         });
       }
   }
}
