import React from 'react'
import Modal from '../common/Modal';
import { useFormik } from 'formik';
import axios from './../../config/axios';
import Cookies from 'js-cookie';

export default function ConfirmUserAccountModal({user, isOpen, closeModal}) {
   
      // user/give/confirm-account

      let formik = useFormik({
         initialValues: {},

         onSubmit: async () => {
            try{
               let res = await axios.post('/user/give/confirm-account', {
                  user_id: user.id
               })

               if(res.status === 200){
                  Cookies.set('success', res.data, {
                     expires: new Date(new Date().getTime() + 15 * 1000)
                  })

                  window.location.reload()
               }
            }catch(err){
               alert('Something went wrong')
            }
         }
      })


   return (
      <Modal title='تأكيد حساب المستخدم' isOpen={isOpen} onClose={closeModal}>
         <p class="py-2 font-semibold text-gray-700">
            هل أنت متأكد أنك تريد تأكيد حساب هذا المستخدم "{ user.name }"؟
            </p>
         <div class="border-t mt-3 border-gray-300">
            <form class='inline-block' onSubmit={formik.handleSubmit}>
               <button type='submit'
                  class="inline-block mt-5 shadow bg-blue-600 p-2 px-3 rounded text-white font-semibold focus:outline-none hover:bg-blue-700">
                  تأكيد الحساب
               </button>
            </form>

            <button onclick={closeModal} type='button'
               class="inline-block mt-5 mr-3 shadow bg-gray-300 p-2 px-3 rounded text-gray-600 font-semibold focus:outline-none hover:bg-gray-400">
               إلغاء
            </button>
         </div>
      </Modal>
   )
}
