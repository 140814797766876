import React, {useEffect} from 'react'
import axios from '../config/axios';
import ReportsTable from '../components/reported-questions/ReportsTable';

export default function ReportedQuestions() {

   

   return (
      <ReportsTable />
   )
}
