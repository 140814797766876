import React, { useState } from 'react';
import { Menu, Home, Grid, PlusSquare, Flag, Users, UserCheck, UserPlus, HelpCircle, LogOut, List, Clipboard, Tag, Percent, Settings } from 'react-feather';
import axios from '../../config/axios';
import { toast } from 'react-toastify';

export default function Sidebar() {

	// side bar toggler
	let [isOpen, setIsOpen] = useState(false);

	let handleLogout = async () => {

		try {

			let res = await axios.post('/logout');

			if (res.status === 200) {
				window.location.pathname = '/login'
			}

		} catch (err) {
			toast('حدث خطأ ما ، يرجى التحديث وإعادة المحاولة')
		}

	}


	return (
		<React.Fragment>
			{/*Sidebar*/}
				<button onClick={() => setIsOpen(!isOpen)} className='bg-gray-700 p-2 rounded-full absolute top-0 mr-5 mt-1'>
					<Menu size={20} color='#fff' />
				</button>
				<aside
					id="sidebar"
					className={`bg-side-nav w-1/2 md:w-1/6 lg:w-1/6 border-l-2 border-side-nav md:block ${isOpen ? 'block' : 'hidden'}`}
				>
					<ul className="list-reset flex flex-col">
						<li className={`w-full h-full border-b border-light-border hover:bg-white ${
							window.location.pathname === '/' ? 'bg-white' : ''
						}`}>
							<a href="/" className="text-sm text-nav-item no-underline block p-3">
								<Home className='inline-block ml-2 text-gray-700' size={15} />
								لوحة القيادة
							</a>
						</li>
						<li className={`w-full h-full border-b border-light-border hover:bg-white ${
							window.location.pathname === '/topics' ? 'bg-white' : ''
						}`}>
							<a href="/topics" className="text-sm text-nav-item no-underline block p-3">
								<Grid className='inline-block ml-2 text-gray-700' size={15} />
								المواضيع
						</a>
						</li>
						<li className={`w-full h-full border-b border-light-border hover:bg-white ${
							window.location.pathname === '/services/add-category' ? 'bg-white' : ''
						}`}>
							<a href="/services/add-category" className="text-sm text-nav-item no-underline block p-3">
								<PlusSquare className='inline-block ml-2 text-gray-700' size={15} />
								إضافة تخصصات الخدمات
							</a>
						</li>
						<li className={`w-full h-full border-b border-light-border hover:bg-white ${
							window.location.pathname === '/services/categories' ? 'bg-white' : ''
						}`}>
							<a href="/services/categories" className="text-sm text-nav-item no-underline block p-3">
								<List className='inline-block ml-2 text-gray-700' size={15} />
								قائمة تخصصات الخدمات
							</a>
						</li>
						<li className={`w-full h-full border-b border-light-border hover:bg-white ${
							window.location.pathname === '/reports' ? 'bg-white' : ''
						}`}>
							<a href="/reports" className="text-sm text-nav-item no-underline block p-3">
								<Flag className='inline-block ml-2 text-gray-700' size={15} />
								الإبلاغات
						</a>
						</li>
						<li className={`w-full h-full border-b border-light-border hover:bg-white ${
							window.location.pathname === '/advisor/reqst' ? 'bg-white' : ''
						}`}>
							<a href="/advisor/reqst" className="text-sm text-nav-item no-underline block p-3">
								<Clipboard className='inline-block ml-2 text-gray-700' size={15} />
								طلب المستشارين
							</a>
						</li>
						<li className={`w-full h-full border-b border-light-border hover:bg-white ${
							window.location.pathname === '/coupons' ? 'bg-white' : ''
						}`}>
							<a href="/coupons" className="text-sm text-nav-item no-underline block p-3">
								<Percent className='inline-block ml-2 text-gray-700' size={15} />
								كوبونات
							</a>
						</li>
						<li className={`w-full h-full border-b border-light-border hover:bg-white ${
							window.location.pathname === '/users' ? 'bg-white' : ''
						}`}>
							<a href="/users" className="text-sm text-nav-item no-underline block p-3">
								<Users className='inline-block ml-2 text-gray-700' size={15} />
								المستخدمين
						</a>
						</li>
						<li className={`w-full h-full border-b border-light-border hover:bg-white ${
							window.location.pathname === '/new-users' ? 'bg-white' : ''
						}`}>
							<a href="/new-users" className="text-sm text-nav-item no-underline block p-3">
								<UserCheck className='inline-block ml-2 text-gray-700' size={15} />
								مستخدمون جدد
						</a>
						</li>
						<li className={`w-full h-full border-b border-light-border hover:bg-white ${
							window.location.pathname === '/add-user' ? 'bg-white' : ''
						}`}>
							<a href="/add-user" className="text-sm text-nav-item no-underline block p-3">
								<UserPlus className='inline-block ml-2 text-gray-700' size={15} />
								إضافة مستخدمين
						</a>
						</li>
						<li className={`w-full h-full border-b border-light-border hover:bg-white ${
							window.location.pathname === '/questions' ? 'bg-white' : ''
						}`}>
							<a href="/questions" className="text-sm text-nav-item no-underline block p-3">
								<HelpCircle className='inline-block ml-2 text-gray-700' size={15} />
								الأسئلة
						</a>
						</li>
						<li className={`w-full h-full border-b border-light-border hover:bg-white ${
							window.location.pathname === '/add-question' ? 'bg-white' : ''
						}`}>
							<a href="/add-question" className="text-sm text-nav-item no-underline block p-3">
								<PlusSquare className='inline-block ml-2 text-gray-700' size={15} />
								إضافة أسئلة
						</a>
						</li>
						<li className={`w-full h-full border-b border-light-border hover:bg-white ${
							window.location.pathname === '/tsaol/settings' ? 'bg-white' : ''
						}`}>
							<a href="/tsaol/settings" className="text-sm text-nav-item no-underline block p-3 text-blue-400">
								<Settings className='inline-block ml-2 text-blue-600' size={15} />
								اعدادات تسأول
						</a>
						</li>
						<li className={`w-full h-full border-b border-light-border hover:bg-white`}>
							<a onClick={handleLogout} href="#" className="text-red-500 text-sm text-nav-item no-underline block p-3">
									<LogOut className='inline-block ml-2 text-red-400' size={15} />
									تسجيل خروج
							</a>
						</li>
					</ul>
				</aside>
			{/*/Sidebar*/}
		</React.Fragment>
	);
}
