import React, { useState } from 'react';
import Modal from './../common/Modal';
import { useFormik } from 'formik';
import axios from './../../config/axios';
import Cookies from 'js-cookie';

export default function DeleteTopicModal({ topic, isOpen, closeModal }) {

   let formik = useFormik({
      onSubmit: async () => {
         let res = await axios.post('/topic/delete', {
            id: topic.id,
         });

         if (res.status === 200) {
            Cookies.set('success', res.data, {
               expires: new Date(new Date().getTime() + 15 * 1000)
            });

            window.location.reload();
         }

      }

   })

   return (
      <Modal title="حذف الموضوع" isOpen={isOpen} onClose={closeModal}>
         <p>هل أنت متأكد أنك تريد حذف موضوع "{topic.name}" ؟</p>
         <form onSubmit={formik.handleSubmit}>
            <div>
               <button
                  type="submit"
                  className="m-2 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded mt-5"
               >
                  حذف
					</button>
               <button
                  onClick={closeModal}
                  type="button"
                  className="m-2 inline-block shadow bg-gray-300 p-2 px-3 rounded text-gray-600 font-semibold focus:outline-none hover:bg-gray-400"
               >
                  إلغاء
					</button>
            </div>
         </form>
      </Modal>
   );
}
