import React from 'react'
import ReqstTable from '../components/consulters-reqst/ReqstTable'

export default function ConsultersRequest() {
   return (
      <div>
         <ReqstTable />
      </div>
   )
}
