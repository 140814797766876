
import axios from '../../config/axios'
import { toast } from 'react-toastify'

export default async function getCoupons(offset, limit){

   try{

      let resp = await axios.get(`/coupons/${offset}/${limit}`);

      // console.log(resp);

      return resp.data;

   }catch(err){
      toast('حدث خطأ ما ، عندما حاولنا الحصول على قائمة القسائم', {className: 'warnToast'})
   }

}

