
import axios from '../../config/axios';
import { toast } from 'react-toastify';

export default async function acceptReqst(data){
   try{

      await axios.post('/consulter/reqst/approve', {consulter: data});

      toast('تمت الموافقة على المستخدم كمستشار !', {className: 'successToast'});

      window.location.reload();
      

   }catch(err){
      toast('هناك خطأ ما', {className: 'errorToast'})
   }
}
