import React, { useState, useEffect } from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';

// Components
import Loader from './components/common/Loader';

// Pages
import Dashboard from './pages/Dashboard';
import Header from './components/common/Header';
import Sidebar from './components/common/Sidebar';
import Topics from './pages/Topics';
import Users from './pages/Users';
import NewUsers from './pages/NewUsers'
import AddUser from './pages/AddUser';
import Questions from './pages/Questions';
import AddQuestion from './pages/AddQuestion';
import ReportedQuestions from './pages/ReportedQuestions';
import axios from './config/axios';
import Login from './pages/auth/Login';
import { AdminProvider } from './config/AdminContext';
import AdminSettings from './pages/AdminSettings';
import AddServiceCategory from './pages/AddServiceCategory';
import ServiceCategories from './pages/ServiceCategories';
import ConsultersRequest from './pages/ConsultersRequest';
import Coupons from './pages/Coupons';
import TsaolSettings from './pages/TsaolSettings';

function App() {

  let [isAdminLoggedIn, setIsAdminLoggedIn] = useState({
    loading: true,
    status: false,
    admin: {}
  })

  // Get logged in admin
  useEffect(() => {
    (async function(){
      try{

        let authRes = await axios.get('/isadminauth');

        // console.log(authRes)
        if(authRes.status === 200){
          setIsAdminLoggedIn({
            loading: false,
            status: true,
            admin: authRes.data.me
          })
        }

      }catch(err){
        setIsAdminLoggedIn({
          loading: false,
          status: false,
          admin: {}
        })
      }


    }());
  }, []);

  

  return (
    <React.Fragment>
      <AdminProvider value={isAdminLoggedIn}>

        <div className="mx-auto bg-grey-400">
          <div className="min-h-screen flex flex-col">
            {
              !isAdminLoggedIn.loading && isAdminLoggedIn.status === true ? (
                <Header /> // <-- Navbar
              ) : null
            }
            <Router>
              <div className="flex flex-1">
                {
                  !isAdminLoggedIn.loading && isAdminLoggedIn.status === true ? (
                    <Sidebar /> // <-- Side bar
                  ) : null
              }
                <main className="bg-gray-200 flex-1 p-3 overflow-hidden">
                  <Switch>
                    {/* Routes */}
                    <Route exact path='/' component={() => {
                     if(isAdminLoggedIn.loading){
                       return <Loader className='mx-auto my-10' />
                     }
                     else{
                       if(!isAdminLoggedIn.loading && isAdminLoggedIn.status === true){
                          return <Dashboard />
                        }else{
                          return <Redirect to='/login' />
                        }
                     }
                    }} />

                    <Route exact path='/topics' component={() => {
                      if(isAdminLoggedIn.loading){
                        return <Loader className='mx-auto my-10' />
                      }
                      else{
                        if (!isAdminLoggedIn.loading && isAdminLoggedIn.status === true) {
                          return <Topics />
                        } else {
                          return <Redirect to='/login' />
                        }
                      }
                    }} />

                    <Route exact path='/users' component={() => {
                      if(isAdminLoggedIn.loading){
                        return <Loader className='mx-auto my-10' />
                      }
                      else{
                        if (!isAdminLoggedIn.loading && isAdminLoggedIn.status === true) {
                          return <Users />
                        } else {
                          return <Redirect to='/login' />
                        }
                      }
                    }} />

                    <Route exact path='/new-users' component={() => {
                      if(isAdminLoggedIn.loading){
                        return <Loader className='mx-auto my-10' />
                      }
                      else{
                        if (!isAdminLoggedIn.loading && isAdminLoggedIn.status === true) {
                          return <NewUsers />
                        } else {
                          return <Redirect to='/login' />
                        }
                      }
                    }} />

                    <Route exact path='/add-user' component={() => {
                      if(isAdminLoggedIn.loading){
                        return <Loader className='mx-auto my-10' />
                      }
                      else{
                        if (!isAdminLoggedIn.loading && isAdminLoggedIn.status === true) {
                          return <AddUser />
                        } else {
                          return <Redirect to='/login' />
                        }
                      }
                    }} />

                    <Route exact path='/questions' component={() => {
                      if(isAdminLoggedIn.loading){
                        return <Loader className='mx-auto my-10' />
                      }
                      else{
                        if (!isAdminLoggedIn.loading && isAdminLoggedIn.status === true) {
                          return <Questions />
                        } else {
                          return <Redirect to='/login' />
                        }
                      }
                    }} />

                    <Route exact path='/add-question' component={() => {
                      if(isAdminLoggedIn.loading){
                        return <Loader className='mx-auto my-10' />
                      }
                      else{
                        if (!isAdminLoggedIn.loading && isAdminLoggedIn.status === true) {
                          return <AddQuestion />
                        } else {
                          return <Redirect to='/login' />
                        }
                      }
                    }} />

                    <Route exact path='/reports' component={() => {
                      if(isAdminLoggedIn.loading){
                        return <Loader className='mx-auto my-10' />
                      }
                      else{
                        if (!isAdminLoggedIn.loading && isAdminLoggedIn.status === true) {
                          return <ReportedQuestions />
                        } else {
                          return <Redirect to='/login' />
                        }
                      }
                    }} />

                    <Route exact path='/services/add-category' component={() => {
                      if(isAdminLoggedIn.loading){
                        return <Loader className='mx-auto my-10' />
                      }
                      else{
                        if (!isAdminLoggedIn.loading && isAdminLoggedIn.status === true) {
                          return <AddServiceCategory />
                        } else {
                          return <Redirect to='/login' />
                        }
                      }
                    }} />

                    <Route exact path='/services/categories' component={() => {
                      if(isAdminLoggedIn.loading){
                        return <Loader className='mx-auto my-10' />
                      }
                      else{
                        if (!isAdminLoggedIn.loading && isAdminLoggedIn.status === true) {
                          return <ServiceCategories />
                        } else {
                          return <Redirect to='/login' />
                        }
                      }
                    }} />

                    <Route exact path='/advisor/reqst' component={() => {
                      if(isAdminLoggedIn.loading){
                        return <Loader className='mx-auto my-10' />
                      }
                      else{
                        if (!isAdminLoggedIn.loading && isAdminLoggedIn.status === true) {
                          return <ConsultersRequest />
                        } else {
                          return <Redirect to='/login' />
                        }
                      }
                    }} />

                    <Route exact path='/coupons' component={() => {
                      if(isAdminLoggedIn.loading){
                        return <Loader className='mx-auto my-10' />
                      }
                      else{
                        if (!isAdminLoggedIn.loading && isAdminLoggedIn.status === true) {
                          return <Coupons />
                        } else {
                          return <Redirect to='/login' />
                        }
                      }
                    }} />

                    {/* ####### admin settings ####### */}
                    <Route exact path='/admin/settings' component={() => {
                      if (isAdminLoggedIn.loading) {
                        return <Loader className='mx-auto my-10' />
                      }
                      else {
                        if (!isAdminLoggedIn.loading && isAdminLoggedIn.status === true) {
                          return <AdminSettings />
                        } else {
                          return <Redirect to='/login' />
                        }
                      }
                    }} />

                    {/* ####### tsaol settings ####### */}
                    <Route exact path='/tsaol/settings' component={() => {
                      if (isAdminLoggedIn.loading) {
                        return <Loader className='mx-auto my-10' />
                      }
                      else {
                        if (!isAdminLoggedIn.loading && isAdminLoggedIn.status === true) {
                          return <TsaolSettings />
                        } else {
                          return <Redirect to='/login' />
                        }
                      }
                    }} />


                    {/* ####### Login ####### */}
                    <Route exact path='/login' component={() => {
                      if(isAdminLoggedIn.loading){
                        return <Loader className='mx-auto my-10' />
                      }
                      else{
                        if (!isAdminLoggedIn.loading && isAdminLoggedIn.status === true) {
                          return <Redirect to='/' />
                        } else {
                          return <Login />
                        }
                      }
                    }} />


                  </Switch>
                </main>
              </div>
            </Router>
          </div>
        </div>
      </AdminProvider>
    </React.Fragment>
  );
}

export default App;
