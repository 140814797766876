import React, { useState } from "react";
import { BarChart2 } from "react-feather";
import toggleCoupon from "../../api/coupons/toggleCoupon";
import '../../../node_modules/rc-switch/assets/index.css'
import Switch from 'rc-switch';
import timeAgo from 'nta'
import StatsModal from "./StatsModal";

export default function CouponCard({ coupon }) {

  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)

   const handleToggleChange = async (e) => {
      // console.log(e)
      await toggleCoupon(coupon.id, !e);
   }

  return (
    <React.Fragment>
      <div className="shadow px-5 py-3 bg-white rounded-lg m-3">
      <div className="mb-3">
        <p className='mb-2 text-sm'>
          <span className="text-gray-400 text-sm ml-1 inline-block">نوع :</span>
          {coupon.coupon_type == 1 ? "كوبون عام" : "كوبون خاص (للتسويق)"}
        </p>
        <p className='mb-2 text-sm'>
          <span className="text-gray-400 text-sm ml-1 inline-block">قيمة الخصم :</span>
          {coupon.discount}%
        </p>
        <p className='mb-2 text-sm'>
          <span className="text-gray-400 text-sm ml-1 inline-block">أنشئ :</span>
          {timeAgo(coupon.created_at)}
        </p>
      </div>
      <div className="flex items-center justify-between flex-wrap border-t border-gray-200 py-3">
        {coupon.coupon_type == 2 ? (
            <p className='my-1'>
               {coupon.influancer_name} <span className='text-sm text-gray-400'>(المؤثر)</span>
            </p>
        ) : null}
        <button onClick={() => setIsStatsModalOpen(true)} className="my-1 focus:outline-none border border-blue-200 hover:border-blue-300 bg-blue-100 px-2 py-1 rounded-lg text-blue-500 flex items-center text-sm cursor-pointer hover:text-blue-600">
          <BarChart2 size={15} /> <span>احصائيات</span>
        </button>
        <span className='my-1'>
           <Switch
            onChange={handleToggleChange}
            itemID={coupon.id}
            defaultChecked={!coupon.disabled}
           />
        </span>
      </div>
    </div>

    {
      isStatsModalOpen ? (
        <StatsModal 
          isOpen={true}
          closeModal={() => setIsStatsModalOpen(false)}
          coupon_id={coupon.id}
        />
      ) : null
    }
    </React.Fragment>
  );
}
