import axios from '../../config/axios';
import { toast } from 'react-toastify';

export default async function createCategory(data){
   try {
      let res = await axios.post("/create/speciality", data);
      if (res.status === 200) {
        toast("تم إنشاء التخصص بنجاح", { className: "successToast" });
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 400) {
          toast(err.response.data[0], { className: "errorToast" });
        }
      } else {
        toast("حدث خطأ ما ، يرجى إعادة المحاولة لاحقًا", {
          className: "errorToast",
        });
      }
    }
}
