import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import axios from './../../config/axios';
import Cookies from 'js-cookie';


export default function CreateTopicForm() {

   let formik = useFormik({
      initialValues: {
         name: '',
         status: ''
      },

      validationSchema: Yup.object().shape({
         name: Yup.string()
            .required('يرجى إدخال اسم الموضوع')
            .max(40, 'اسم الموضوع طويل جدًا ، يُرجى المحاولة لجعله أقصر')
         , status: Yup.string()
            .required('يرجى تحديد الحالة')
      }),

      onSubmit: async ({ name, status }) => {

         let res = await axios.post('/topic/create', {
            topic: name,
            status
         });

         if (res.status === 200) {
            Cookies.set('success', res.data, {
               expires: new Date(new Date().getTime() + 15 * 1000)
            });

            window.location.reload();
         }

      }
   })

   return (
      <div className="mt-3 mb-5 border-2 border-gray-400 border-dashed py-5 px-3">
         <div className="w-full md:w-1/2 mx-auto">
            <h1 className="text-lg mb-2 font-bold"> إنشاء موضوع جديد </h1>
            <form onSubmit={formik.handleSubmit}>
               <input
                  type="text"
                  placeholder="اسم الموضوع"
                  name="name"
                  onChange={formik.handleChange}
                  size={20}
                  className="appearance-none block bg-white text-grey-darker border-2 border-gray-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white-500 focus:border-gray-600 mt-2 w-full"
               />
               <p className='text-red-400 text-sm mt-1'>{formik.errors.name}</p>
               <select
                  name="status"
                  onChange={formik.handleChange}
                  className="block appearance-none bg-grey-200 border-2 border-gray-400 text-grey-darker py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-greymt-2 mt-3 w-full"
               >
                  <option value=''> اختر الحالة (الرؤية على الصفحة الرئيسية)</option>
                  <option value={1}> عامة </option>
                  <option value={0}> مخفي </option>
               </select>
               <p className='text-red-400 text-sm mt-1'>{formik.errors.status}</p>
               <button
                  type="submit"
                  className="bg-green-500 hover:bg-green-800 text-white font-bold py-2 px-4 rounded mt-5 w-full"
               >
                  إنشاء
               </button>
            </form>
         </div>
      </div>
   )
}
