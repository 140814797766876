import React from 'react';
import Modal from '../common/Modal';
import { useFormik } from 'formik';
import axios from '../../config/axios';
import Cookies from 'js-cookie';

export default function GiveSpecialBadgeModal({ user, isOpen, closeModal }) {
	// user/give/special-badge

   let formik = useFormik({
      initialValues: {},

      onSubmit: async () => {

         try{

            let res = await axios.post('/user/give/special-badge', {
               user_id: user.id
            })
            if(res.status === 200){
               Cookies.set('success', res.data, {
                  expires: new Date(new Date().getTime() + 15 * 1000)
               });

               window.location.reload()
            }

         }catch(err){
            alert('Something went wrong')
         }

      }
   });

	return (
		<Modal title="إعطاء شارة خاصة" isOpen={isOpen} onClose={closeModal}>
			
         <div>
            <p className="py-2 text-gray-700 block whitespace-pre-wrap">
               هل أنت متأكد أنك تريد {user.special_badge == 1 ? 'نزع' : 'منح'} هذا المستخدم "<b>{user.fullname}</b>"
               شارة خاصة؟ تسمح هذه الشارة لهذا المستخدم بوضع إجاباته في الأعلى بغض النظر عن أي شيء
            </p>
         </div>
         
			<div class="border-t mt-3 border-gray-300">
				<form class="inline-block" onSubmit={formik.handleSubmit}>
					{user.special_badge == 1 ? (
						<button
							type="submit"
							class="inline-block mt-5 shadow bg-red-600 p-2 px-3 rounded text-white font-semibold focus:outline-none hover:bg-red-700"
						>
							نزع
						</button>
					) : (
						<button
							type="submit"
							class="inline-block mt-5 shadow bg-blue-600 p-2 px-3 rounded text-white font-semibold focus:outline-none hover:bg-blue-700"
						>
							منح
						</button>
					)}
				</form>

				<button
               onClick={closeModal}
					type="button"
					class="inline-block mt-5 mr-3 shadow bg-gray-300 p-2 px-3 rounded text-gray-600 font-semibold focus:outline-none hover:bg-gray-400"
				>
					إلغاء
				</button>
			</div>
		</Modal>
	);
}
