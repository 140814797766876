// resolves image path, and returns the working img

import config from '../config/config';

export default function resolveImg(img) {
   let url = '';

   // if(!img){
   //    return null;
   // }

   if (img.indexOf('storage') === 1) {
      url = `${config.pure_server_url}${img}`;
   } else {
      url = `${img}`;
   }

   return url;
}
