import React, { useState } from 'react'
import timeAgo from 'nta';
import { Trash, CheckCircle, Award } from 'react-feather';
import DeleteUserModal from './DeleteUserModal';
import GiveSpecialBadgeModal from './GiveSpecialBadgeModal';
import ConfirmUserAccountModal from './ConfirmUserAccountModal';
// import EditTopicModal from './EditTopicModal';
// import DeleteTopicModal from './DeleteTopicModal';


export default function UserTableRow({ user }) {

   let [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
   let [isSpecialBadgeOpen, setIsSpecialBadgeOpen] = useState(false);
   let [isConfirmAccountOpen, setIsConfirmAccountOpen] = useState(false);

   return (
      <tr>
         <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            {user.fullname}
         </td>
         <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            {
               user.verified === 1
                  ? <span className='bg-green-100 text-green-500 rounded-full py-1 px-2 text-sm'>تم تأكيد</span>
                  : <span className='bg-red-100 text-red-500 rounded-full py-1 px-2 text-sm'>غير مؤكد</span>
            }
         </td>
         <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            {user.method === 'local' || user.method === '' ? 'البريد الإلكتروني' : user.method}
         </td>
         <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            {timeAgo(user.created_at)}
         </td>

         <td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
         
            <span aria-label='delete-topic' className='inline-block bg-red-100 p-2 cursor-pointer hover:bg-red-200 rounded-full' title='حذف المستخدم' onClick={() => setIsDeleteModalOpen(true)}> <Trash size={18} className='text-red-500' /> </span>
         
            <span aria-label='delete-topic' className='inline-block bg-blue-100 mr-3 p-2 cursor-pointer hover:bg-blue-200 rounded-full' title='شارة المستخدم الخاصة' onClick={() => setIsSpecialBadgeOpen(true)}> <Award size={18} className='text-blue-500' /> </span>

            {
               user.verified === 0 ? (
                  <span aria-label='edit-topic' className='inline-block bg-gray-100 p-2 cursor-pointer hover:bg-gray-200 rounded-full mr-3 text-gray-600' onClick={() => setIsConfirmAccountOpen(true)} title='تأكيد الحساب'> <CheckCircle size={18} /> </span>
               ) : null
            }
            {/* Modals */}
            <DeleteUserModal 
               user={user}
               isOpen={isDeleteModalOpen}
               closeModal={() => setIsDeleteModalOpen(false)}
            />

            <GiveSpecialBadgeModal
               user={user}
               isOpen={isSpecialBadgeOpen}
               closeModal={() => setIsSpecialBadgeOpen(false)}
               />

            <ConfirmUserAccountModal
               user={user}
               isOpen={isConfirmAccountOpen}
               closeModal={() => setIsConfirmAccountOpen(false)}
            />
         </td>
      </tr>
   )
}
