import React from 'react'
import CouponsList from '../components/coupons/CouponsList'

export default function Coupons() {
   return (
      <div>
         <CouponsList />
      </div>
   )
}
