const Configs = function () {
  this.env = "PROD";

  // this.env= 'PROD';

  this.port = "5000";

  this.pure_server_url =
    this.env == "DEV"
      ? `http://localhost:${this.port}`
      : "https://tsaol-api.tsaol.net";
  this.server_url =
    this.env == "DEV"
      ? `http://localhost:${this.port}/admin-panel`
      : "https://tsaol-api.tsaol.net/admin-panel";
};

let config = new Configs();
export default config;
