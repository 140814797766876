import React from 'react'
import Modal from '../common/Modal'
import CreateCouponForm from './CreateCouponForm'


export default function CreateCouponModal({isOpen, closeModal}) {
   return (
      <Modal isOpen={isOpen} onClose={closeModal} title='إنشاء كوبون جديد'>
         <CreateCouponForm />
      </Modal>
   )
}
