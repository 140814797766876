import React, { useState } from 'react'
import { Trash } from 'react-feather';
import DeleteQuestionModal from '../questions/DeleteQuestionModal';

export default function ReportTableRow({report}) {

   let [isDeleteQuestionOpen, setIsDeleteQuestionOpen] = useState(false);

   return (
      <tr>
         <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            {report.fullname}
         </td>
         <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            {report.title}
         </td>
         <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            {report.reason}
         </td>

         <td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
            <button type='button' aria-label='delete-topic' className='flex items-center bg-red-100 p-2 cursor-pointer hover:bg-red-200 rounded-full' title='حذف السؤال' onClick={() => setIsDeleteQuestionOpen(true)}>
               <Trash size={18} className='text-red-500' />
               <span className='text-sm mr-2 text-red-500'>حذف السؤال</span>
            </button>
         </td>

         <DeleteQuestionModal
            question={{ title: report.title, id: report.q_id}}
            isOpen={isDeleteQuestionOpen}
            closeModal={() => setIsDeleteQuestionOpen(false)}
         />
      </tr>
   )
}
