import axios from '../../config/axios'
import { toast } from 'react-toastify'

export default async function createCoupon(data = {}){

   try{

      let resp = await axios.post('/coupon/create', data);

      toast('تم إنشاء القسيمة!', {className: 'successToast'});

      // setTimeout(() => {
         window.location.reload()
      // }, 1000)

   }catch(err){
      toast('هناك شئ خاطئ، يرجى المحاولة فى وقت لاحق', {className: 'warnToast'})
   }

}

