import React, { useState } from 'react';
import Modal from './../common/Modal';
import {useFormik} from 'formik';
import * as Yup from 'yup'
import axios from './../../config/axios';
import Cookies from 'js-cookie';

export default function EditTopicModal({topic, isOpen, closeModal}) {

   let formik = useFormik({
      initialValues: {
         topic_id: topic.id,
         topic_name: topic.name,
         topic_status: topic.status
      },
      validateOnBlur: true,
      validationSchema: Yup.object().shape({
         topic_name: Yup.string()
            .required('يرجى إدخال اسم الموضوع')
            .max(40, 'اسم الموضوع طويل جدًا ، يُرجى المحاولة لجعله أقصر')
         , topic_status: Yup.string()
            .required('يرجى تحديد الحالة')
      }),
      onSubmit: async ({topic_id, topic_name, topic_status}) => {

         let res = await axios.post('/topic/edit', {
            topic_id,
            topic_name,
            topic_status
         });

         if(res.status === 200){
            Cookies.set('success', res.data, {
               expires: new Date(new Date().getTime() + 15 * 1000)
            });

            window.location.reload();
         }

      }

   })

	return (
      <Modal title="تحديث الموضوع" isOpen={isOpen} onClose={closeModal}>
			<form onSubmit={formik.handleSubmit}>
				<input
					type="text"
					placeholder="اسم الموضوع"
               name="topic_name"
               defaultValue={formik.values.topic_name}
               onChange={formik.handleChange}
					size={20}
               className={`w-full appearance-none block bg-white text-grey-darker border-2 border-gray-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white-500 focus:border-gray-600 ${formik.errors.topic_name ? 'border-red-400' : ''}`}
				/>
            <p className='text-red-400 text-sm mt-1'> {formik.errors.topic_name} </p>
				<select
               name="topic_status"
               onChange={formik.handleChange}
               className={`block appearance-none bg-grey-200 border-2 border-gray-400 text-grey-darker py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-greymt-2 mt-3 w-full ${formik.errors.topic_status ? 'border-red-400' : ''}`}
				>
					<option value=''> اختر الحالة (الرؤية على الصفحة الرئيسية)</option>
               <option value={1} selected={formik.values.topic_status === 1}> عامة </option>
               <option value={0} selected={formik.values.topic_status === 0}> مخفي </option>
				</select>
            <p className='text-red-400 text-sm mt-1'> {formik.errors.topic_status} </p>
				<div>
					<button
						type="submit"
						className="m-2 bg-green-500 hover:bg-green-800 text-white font-bold py-2 px-4 rounded mt-5"
					>
						تحديث
					</button>
					<button
						onClick={closeModal}
						type="button"
						className="m-2 inline-block shadow bg-gray-300 p-2 px-3 rounded text-gray-600 font-semibold focus:outline-none hover:bg-gray-400"
					>
						إلغاء
					</button>
				</div>
			</form>
		</Modal>
	);
}
