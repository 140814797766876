import React, { useState, useEffect } from "react";
import getCoupons from "../../api/coupons/getCoupons";
import Loader from "../common/Loader";
import CreateCouponModal from "./CreateCouponModal";
import { Plus, ArrowRight, ArrowLeft } from "react-feather";
import CouponCard from "./CouponCard";

export default function CouponsList() {
  const [isCreateCouponOpen, setIsCreateCouponOpen] = useState(false);
  const [offset, setOffset] = useState(0);
  const [coupons, setCoupons] = useState({ loading: true, data: [], total: 0 });
  const limit = 6;
  let [noMoreNext, setNoMoreNext] = useState(false);
  let [noMorePrev, setNoMorePrev] = useState(true);

  useEffect(() => {
    (async function () {
      let resp = await getCoupons(offset, limit);

      // console.log(coupons)
      setCoupons({
        loading: false,
        data: resp.coupons,
        total: resp.totalCoupons
      });
      // alert(resp.coupons.length + ' ' + resp.totalCoupons)
      // check for next & prev btns
       if ((offset === 0 && resp.coupons.length < limit) || (resp.coupons.length == resp.totalCoupons)) {
         setNoMoreNext(true);
         setNoMorePrev(true);
       } else if ((offset > 0 && resp.coupons.length < limit) || (offset+limit == resp.totalCoupons)) {
         setNoMoreNext(true);
         setNoMorePrev(false);
       } else if (offset > 0 && resp.coupons.length >= limit) {
         setNoMoreNext(false);
         setNoMorePrev(false);
       } else {
         setNoMoreNext(false);
         setNoMorePrev(true);
       }
    })();
  }, [offset]);

  const nextCoupons = () => {
     if(!noMoreNext){
       setCoupons(prevState => {
         return {data: prevState.data, total: prevState.total, loading: true}
       })
        setOffset((prevState) => {
          return prevState + limit
        })
     }
  }

  const prevCoupons = () => {
     if(!noMorePrev){
       setCoupons(prevState => {
         return {data: prevState.data, total: prevState.total, loading: true}
       })
      setOffset((prevState) => {
         return prevState - limit
      })
     }
  }

  return (
    <div className="m-1">
      <div className="px-2 py-3 bg-gray-300 rounded-lg text-gray-800">
        <p className="font-bold"> 
         كوبونات التخفيض ({coupons.total}) 
        </p>
      </div>

      <button
        onClick={() => setIsCreateCouponOpen(true)}
        className="px-3 py-2 rounded-full bg-blue-500 hover:bg-blue-600 transition text-white shadow m-2 mt-4"
      >
        <Plus className="inline-block ml-1" size={18} /> كوبون جديد
      </button>

      <div className="flex flex-wrap -mx-2">
        {coupons.loading ? (
          <Loader className="mx-auto my-10" />
        ) : coupons.data == 0 ? (
          <p className='py-5 text-center font-bold text-gray-700 block mx-auto'> لم يتم إنشاء أي كوبونات حتى الآن </p>
        ) : (
          coupons.data.map((coupon) => (
            <div className="my-2 px-2 w-full lg:w-1/3">
              <CouponCard coupon={coupon} />
            </div>
          ))
        )}
      </div>

      <div className='mt-5 text-center py-10'>
         <button
            onClick={nextCoupons}
            type="button"
            class={`-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150 ${
            noMoreNext ? "cursor-not-allowed" : ""
            }`}
            aria-label="Next"
         >
            <ArrowRight />
         </button>

         <button
            onClick={prevCoupons}
            type="button"
            class={`relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150 ${
            noMorePrev ? "cursor-not-allowed" : ""
            }`}
            aria-label="Previous"
         >
            <ArrowLeft />
         </button>
      </div>

      <CreateCouponModal
        isOpen={isCreateCouponOpen}
        closeModal={() => setIsCreateCouponOpen(false)}
      />
    </div>
  );
}
