import React from 'react';
import CreateTopicForm from '../components/topics/CreateTopicForm';
import TopicsTable from './../components/topics/TopicsTable';
import Cookies from 'js-cookie'
import SuccessMsg from './../components/common/alerts/Success';

export default function Topics() {
	return (
   <React.Fragment>
      <div class="container mx-auto">
         {
            Cookies.get('success') ? <SuccessMsg body={Cookies.get('success')} /> : null
         }
         {/* Create Topic form */}
         <CreateTopicForm />

         {/* Topics table */}
         <TopicsTable />

         
      </div>
   </React.Fragment>
	);
}
