import React, { useState, useEffect } from 'react'
import Modal from '../common/Modal'
import { Tag, DollarSign, Users } from 'react-feather'
import getCouponStats from '../../api/coupons/getCouponStats';

export default function StatsModal({isOpen, closeModal, coupon_id}) {

   const [stats, setStats] = useState({
      loading: true,
      data: []
   });
   // alert(coupon_id)

   useEffect(() => {
      (async function(){
         let stats = await getCouponStats(coupon_id);
         console.log(stats)
         setStats({
            loading: false,
            data: stats
         })
      }())
   }, [])
   return (
      <Modal isOpen={isOpen} onClose={closeModal} title='إحصائيات'>
         <div className='flex items-center justify-around'>
            <div className='p-1'>
               <Tag className='mb-1 mx-auto block text-gray-700' size={35} />
               <p className='text-gray-500 text-center'> مرات الاستخدام </p>
               <b className='text-lg text-gray-800 text-center block'> 
                  {stats.loading ? '-' : `${stats.data.used_times}`} 
               </b>
            </div>
            <div className='p-1'>
               <Users className='mb-1 mx-auto block text-gray-700' size={35} />
               <p className='text-gray-500 text-center'> عدد العملاء </p>
               <b className='text-lg text-gray-800 text-center block'> 
                  {stats.loading ? '-' : `${stats.data.client_nums}`} 
               </b>
            </div>
            <div className='p-1'>
               <DollarSign className='mb-1 mx-auto block text-gray-700' size={35} />
               <p className='text-gray-500 text-center'> مبيعات الكوبون </p>
               <b className='text-lg text-gray-800 text-center block'>
                  {stats.loading ? '-' : `${stats.data.total_sales}`} 
                  <DollarSign className='inline-block' size={13} /> 
               </b>
            </div>
         </div>

         <button onClick={closeModal} className='bg-gray-300 text-gray-600 hover:bg-gray-400 px-5 py-2 rounded-lg text-center mx-auto mt-10'> أغلق </button>
      </Modal>
   )
}
