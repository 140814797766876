import React from "react";
import Modal from "./../common/Modal";
import Loader from "../common/Loader";
import { useFormik } from "formik";
import acceptReqst from "../../api/consulters-reqsts/accpetReqst";

export default function ApproveModal({ consulter, isOpen, closeModal }) {
  let formik = useFormik({
    initialValues: {
      consulter_data: consulter,
    },

    onSubmit: async ({ consulter_data }) => {
      await acceptReqst(consulter_data);
    },
  });

  return (
    <Modal title="قبول كمستشار" isOpen={isOpen} onClose={closeModal}>
      <form onSubmit={formik.handleSubmit}>
        <p>
          هل أنت متأكد أنك تريد <b>قبول</b> "{consulter.fullname}" كمستشار في
          تساؤل
        </p>
        <div className="flex items-center justify-between">
          <span
            className="w-full block bg-gray-200 cursor-pointer hover:bg-gray-300 text-gray-600 p-2 rounded shadow mt-16 rounded-full mx-2 text-center"
            onClick={closeModal}
          >
            أغلق
          </span>
          {formik.isSubmitting ? (
            <span className="w-full block bg-blue-400 hover:bg-blue-500 text-white p-2 rounded shadow mt-16 rounded-full mx-2">
              <Loader className="mx-auto" />
            </span>
          ) : (
            <button
              type="submit"
              className="w-full block bg-blue-400 hover:bg-blue-500 text-white p-2 rounded shadow mt-16 rounded-full mx-2"
            >
              قبول
            </button>
          )}
        </div>
      </form>
    </Modal>
  );
}
