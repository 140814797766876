import React from 'react'
import UsersTable from '../components/users/UsersTable';
import Cookies from 'js-cookie'
import SuccessMsg from '../components/common/alerts/Success';

export default function Users() {
   return (
      <React.Fragment>
         {
            Cookies.get('success') ? <SuccessMsg body={Cookies.get('success')} /> : null
         }

         {/* the users table */}
         <UsersTable />
      </React.Fragment>
   )
}
