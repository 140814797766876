import React from "react";
import {useFormik} from 'formik';
import * as yup from 'yup'
import createCoupon from "../../api/coupons/createCoupon";

/*
   e.g: price = $50, DISCOUNNT_NUM = 10%;

   discount formula: price - (price * (DISCOUNT_NUM / 100) )

*/

export default function CreateCouponForm() {
   
   const formik = useFormik({

      initialValues: {
         coupon_code: '',
         coupon_type: '',
         discount: 0,
         influancer_name: ''
      },

      validationSchema: yup.object().shape({
         coupon_code: yup.string()
                           .required('الرجاء إدخال رمز الكوبون')
                           .max(30, 'الكوبون طويل جدًا، لا يمكن أن يحتوي على أكثر من 30 حرفًا')
         , coupon_type: yup.string()
                              .required('الرجاء اختيار نوع الكوبون')

         , discount: yup.number()
                           .max(100, 'أدخل قيمة الخصم')
                           .min(1, 'الحد الأدنى لقيمة الخصم هو 1٪')

         , influancer_name: yup.string()
                                 .max(50)
                                 .nullable()
      }),

      onSubmit: async (data, {setErrors}) => {
         if(data.coupon_type == 2 && !data.influancer_name){
            setErrors({influancer_name: 'الرجاء إدخال اسم الشخص'})
            return;
         }
         // make a request to the server
         await createCoupon(data)
      }

   })


  return (
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-10">
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="coupon_code"
            type="text"
            autoComplete='off'
            placeholder="رمز الكوبون"
            onChange={formik.handleChange}
          />
          <p className='text-sm text-red-400 mt-1'>{formik.errors.coupon_code}</p>
        </div>

        <div className="flex flex-wrap md:flex-no-wrap mb-10">
          <div className="relative w-full md:ml-3 mb-10 md:mb-0">
            <select
              className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pl-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
              name="coupon_type"
              onChange={formik.handleChange}
            >
              <option value="" selected className="text-gray-400">
                حدد نوع الكوبون
              </option>
              <option value="1">كوبون عام</option>
              <option value="2">كوبون خاص (للتسويق)</option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center px-2 text-gray-700">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
            <p className='text-sm text-red-400 mt-1'>{formik.errors.coupon_type}</p>
          </div>

          <div className="w-full">
            <div className="flex">
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="discount"
                type="number"
                min='1'
                placeholder="قيمة خصم الكوبون"
                onChange={formik.handleChange}
              />
              <span
                className="bg-gray-300 text-gray-600 py-2 px-4 rounded-l"
                style={{ marginRight: "-45px" }}
              >
                %
              </span>
            </div>
            <p className='text-sm text-red-400 mt-1'>{formik.errors.discount}</p>
          </div>
        </div>

        {
           formik.values.coupon_type == 2 ? (
            <div className="mb-10">
               <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  name="influancer_name"
                  type="text"
                  placeholder="اسم الشخص (المؤثر) الذي ستمنحه الكوبون!"
                  onChange={formik.handleChange}
               />
               <p className='text-sm text-red-400 mt-1'>{formik.errors.influancer_name}</p>
            </div>
           ) : null
        }

        <button type='submit' className='p-2 bg-blue-500 hover:bg-blue-600 rounded-lg text-white shadow w-full mt-5'>  إنشاء الكوبون </button>
      </form>
  );
}
