import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import axios from '../../config/axios';
import Loader from '../common/Loader';
import SuccessMsg from '../common/alerts/Success';

export default function AddQuestionForm() {
	let [ topics, setTopics ] = useState([]);
	let [ loadingTopics, setLoadingTopics ] = useState(true);
	let [ excp, setExcp ] = useState(false);
	let [ loadingCreation, setLoadingCreation ] = useState(false);

	// Get topics
	useEffect(() => {
		(async function() {
			try {
				let topics = await axios.get('/topics');

				setTopics(topics.data.topics);
				setLoadingTopics(false);
			} catch (e) {
				alert('يبدو أن هناك خطأ ما ، نحن نعمل بجد لإصلاحه');
			}
		})();
	}, []);

	const topicsOptions = [];
	if (!loadingTopics) {
		topics.map((topic) => {
			topicsOptions.push({ value: topic.id, label: topic.name });
		});
	}

	const pointsOptions = [
		{ value: '10', label: '10 نقاط' },
		{ value: '20', label: '20 نقطة' },
		{ value: '30', label: '30 نقطة' },
		{ value: '40', label: '40 نقطة' },
		{ value: '50', label: '50 نقطة' },
		{ value: '60', label: '60 نقطة' }
	];

	let question_starts_with = [ 'كم', 'لماذا', 'أين', 'هل', 'ماذا', 'ما', 'متى', 'كيف' ];
	let formik = useFormik({
		initialValues: {
			question: '',
			points: pointsOptions[0],
			topics: []
		},
		validateOnBlur: true,
		validationSchema: Yup.object().shape({
			question: Yup.string()
				.matches(
					/^(كم|لماذا|أين|هل|ماذا|ما|متى|كيف)/i,
					`يجب أن يبدأ سؤالك بـ ${question_starts_with.join(',')}`
				)
				.min(10, 'السؤال قصير للغاية ، حاول أن تجعله أكثر وضوحًا')
				.max(200, 'سؤالك طويل جدا ، يرجى المحاولة لجعله أقصر')
				.required('يرجى كتابة سؤالك'),
			topics: Yup.array()
				.min(1, 'يرجى اختيار موضوع على الأقل')
				.max(3, 'لا يمكنك اختيار أكثر من 3 مواضيع')
				.nullable()
				.of(
					Yup.object().shape({
						label: Yup.string().required(),
						value: Yup.string().required()
					})
				)
		}),
		// { question, points, topics }
		onSubmit: async ({ question, topics, points }) => {
			// console.log(values);
			try {
				setLoadingCreation(true);
				let createQuestRes = await axios.post('/add-question', {
					title: question.replace(/[-/]/g, ' ').replace(/  +/g, ' '),
					topics: topics,
					points: points.value
				});
				// console.log('createQuestRes', createQuestRes)
				if (createQuestRes.status === 200) {
					Cookies.set('success', createQuestRes.data, {
						expires: new Date(new Date().getTime() + 10 * 1000)
					});
					window.location.reload();
				} else if (createQuestRes.status === 202) {
					alert(createQuestRes.data.error)
				}
			} catch (err) {
				// console.log(err)
            alert('يبدو أن هناك خطأ ما ، نحن نعمل بجد لإصلاحه')
			}
		}
	});
	return (
		<div className="container mx-auto">
			<div className="mt-10">
				{Cookies.get('success') ? <SuccessMsg body={Cookies.get('success')} /> : null}
				<div className="w-full md:w-1/2 mx-auto">
               <h1 className="text-center text-2xl mb-5 font-bold"> أضف سؤال جديد </h1>
					<form onSubmit={formik.handleSubmit}>
						<div class="mb-20">
							<label
								class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
								for="grid-first-name"
							>
								اكتب سؤالك
							</label>
							<div class="relative">
								<input
									class="rounded w-full p-2 py-4 border-2 text-black leading-tight focus:outline-none border-blue-400 focus:border-blue-500"
									onChange={formik.handleChange}
									value={formik.values.question}
									name="question"
									type="text"
									placeholder="ابدأ سؤالك بـ ماذا ، كيف ، إلخ ..."
								/>
							</div>
							<div class="flex items-center justify-between mt-2">
								<p class="text-red-600 text-sm">{formik.errors.question}</p>
								<div id="charsLeft" class="font-bold text-left float-left">
									200/<span id="usedChars">0</span>
								</div>
							</div>
						</div>
						<div class="flex flex-wrap">
							<div class="w-full md:w-3/4 px-3 ">
								<label class="block text-gray-700 text-sm font-bold mb-2">
									تصنيفك الصحيح لسؤالك يسهل الحصول على إجابتك
								</label>
								<div class="block relative">
									<Select
										isLoading={loadingTopics ? true : false}
										closeMenuOnSelect={false}
										components={makeAnimated()}
										placeholder="اختر الموضوع"
										isMulti
										isSearchable
										name="topics"
										isRtl={true}
										options={loadingTopics ? [] : topicsOptions}
										noOptionsMessage={() => 'لا مزيد من المواضيع'}
										onChange={(v) => {
											if (v == null || v.length < 4) {
												formik.setFieldValue('topics', v);
											}
										}}
										value={formik.values.topics}
									/>
								</div>
								<p class="text-red-600 text-sm mt-2">{formik.errors.topics}</p>
								<div id="topicsTags" class="flex flex-wrap items-center mt-3" />
							</div>
							<div class="w-full md:w-1/4 px-3">
								<label class="block text-gray-700 text-sm font-bold mb-2">كم نقطة ؟</label>
								<div class="block relative w-full">
									<Select
										placeholder="نقاط"
										defaultValue={pointsOptions[0]}
										options={pointsOptions}
										name="points"
										isRtl={true}
										onChange={(v) => formik.setFieldValue('points', v)}
										value={formik.values.points}
									/>
								</div>
							</div>
						</div>

						<button
							type="submit"
							class="block mx-auto shadow rounded bg-blue-500 hover:bg-blue-600 px-20 py-3 text-white font-bold mt-16"
						>
							{loadingCreation ? <Loader className="mx-auto" /> : 'انشر سؤالك'}
						</button>
					</form>
				</div>
			</div>
		</div>
	);
}
