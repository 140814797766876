import axios from 'axios';
import conig from './config';
// import Cookies from 'js-cookie'

const instance = axios.create({
   baseURL: conig.server_url,
   withCredentials: true,
   // headers: {
   //    'X-CSRF-TOKEN': Cookies.get('csrfToken')
   // }
});

export default instance;