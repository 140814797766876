import React, { useState } from 'react';
import {useFormik} from 'formik'
import * as Yup from 'yup';
import axios from './../../config/axios';
import Popup from './../common/alerts/Popup';
import Cookies from 'js-cookie';
import Loader from './../common/Loader';

export default function PasswordTab({isOpen}) {

	let [excp, setExcp] = useState(false);
	let [isSending, setIsSending] = useState(false)

	let formik = useFormik({
		initialValues: {
			oldpass: '',
			newpass: '',
			confirmpass: ''
		},

		validateOnBlur: true,
		validationSchema: Yup.object().shape({
			oldpass: Yup.string()
				.required('الرجاء إدخال كلمة المرور الحالية'),

			newpass: Yup.string()
				.required('يرجى إدخال كلمة مرور جديدة')
				.min(5, 'يجب أن تحتوي كلمة المرور على 5 أحرف أو أكثر')
				.max(25, 'قمت بإدخال كلمة مرور طويلة ، الحد الأقصى هو 25 حرف'),

			confirmpass: Yup.string()
				.required('تأكيد كلمة المرور الجديدة')
				.oneOf([Yup.ref('newpass'), null], 'لا تتطابق كلمة المرور المؤكدة مع كلمة المرور الجديدة')
		}),

		onSubmit: async ({ oldpass, newpass, confirmpass }) => {
			setIsSending(true)
			try{

				let res = await axios.post('/setting/password', {
					oldpass,
					newpass,
					confirmpass
				});
				if (res.status === 200) {
					Cookies.set('success', res.data, {
						expires: new Date(new Date().getTime() + 15 * 1000)
					});

					window.location.reload()
				} else if (res.status === 202) {
					formik.setErrors({oldpass: res.data})
				}

			}catch(err){
				setExcp('حدث خطأ ما ، يرجى إعادة المحاولة لاحقًا')
			}

			setIsSending(false)
		}
	})


	return (
      <div
         className={`tab shadow bg-white p-4 py-10 border-r md:border-t border-gray-300 ${!isOpen ? 'hidden': ''}`}
      >
			{excp ? <Popup msg={excp} type='error' /> : null}
			<form onSubmit={formik.handleSubmit}>
				<div className="mb-10">
					<label className="text-gray-700 mb-3 block"> كلمة المرور الحالية </label>
					<input
						className="bg-gray-100 appearance-none border-2 border-gray-500 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
						id="inline-full-name"
						type="password"
						name="oldpass"
						placeholder="كلمة المرور الحالية"
						onChange={formik.handleChange}
					/>
					<p className='text-red-600 text-sm mt-1'>{formik.errors.oldpass}</p>
				</div>
				<div className="mb-5">
					<label className="text-gray-700 mb-3 block"> كلمة المرور الجديدة </label>
					<input
						className="bg-gray-100 appearance-none border-2 border-gray-500 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
						id="inline-full-name"
						type="password"
						name="newpass"
						placeholder="كلمة المرور الجديدة"
						onChange={formik.handleChange}
					/>
					<p className='text-red-600 text-sm mt-1'>{formik.errors.newpass}</p>
				</div>
				<div>
					<input
						className="bg-gray-100 appearance-none border-2 border-gray-500 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
						id="inline-full-name"
						type="password"
						name="confirmpass"
						placeholder="تأكيد كلمة المرور الجديدة"
						onChange={formik.handleChange}
					/>
					<p className='text-red-600 text-sm mt-1'>{formik.errors.confirmpass}</p>
				</div>
				{
					isSending ? (
						<span className="mt-10 block text-center shadow bg-blue-500 hover:bg-blue-600 opacity-50 text-white font-bold py-2 px-4 rounded w-full">
							<Loader className='mx-auto' />
						</span>
					) : (
							formik.values.oldpass !== '' && formik.values.newpass !== '' && !formik.errors.oldpass && !formik.errors.newpass && !formik.errors.confirmpass ? (
								<button
									type="submit"
									className="mt-10 shadow bg-blue-500 hover:bg-blue-600 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded w-full"
								>
									حفظ التغييرات
                        </button>
							) : (
								<span
									className="mt-10 block text-center shadow bg-blue-500 hover:bg-blue-600 opacity-50 text-white font-bold py-2 px-4 rounded w-full"
								>
									حفظ التغييرات
								</span>
							)
						)
				}
			</form>
		</div>
	);
}
