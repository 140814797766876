import React, { useState } from 'react'
import timeAgo from 'nta';
import { Edit2, Trash } from 'react-feather';
import EditTopicModal from './EditTopicModal';
import DeleteTopicModal from './DeleteTopicModal';


export default function TopicTableRow({topic}) {

   let [isEditModalOpen, setIsEditModalOpen] = useState(false);
   let [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

   return (
      <tr>
         <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            {topic.name}
         </td>
         <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            {
               topic.status === 1
                  ? <span className='bg-green-100 text-green-500 rounded-full py-1 px-2 text-sm'>ظاهر</span>
                  : <span className='bg-red-100 text-red-500 rounded-full py-1 px-2 text-sm'>مخفي</span>
            }
         </td>
         <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            {timeAgo(topic.created_at)}
         </td>

         <td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
            <span aria-label='edit-topic' className='inline-block bg-blue-100 p-2 cursor-pointer hover:bg-blue-200 rounded-full ml-3' onClick={() => setIsEditModalOpen(true)}> <Edit2 size={18} className='text-blue-500' /> </span>
            
            <span aria-label='delete-topic' className='inline-block bg-blue-100 p-2 cursor-pointer hover:bg-blue-200 rounded-full' onClick={() => setIsDeleteModalOpen(true)}> <Trash size={18} className='text-blue-500' /> </span>

            {/* Modals */}
            <EditTopicModal
               isOpen={isEditModalOpen}
               closeModal={() => setIsEditModalOpen(false)}
               topic={topic}
            />

            <DeleteTopicModal
               isOpen={isDeleteModalOpen}
               closeModal={() => setIsDeleteModalOpen(false)}
               topic={topic}
            />
         </td>
      </tr>
   )
}
