import React from "react";
import Dropzone from "react-dropzone";
import { Upload } from "react-feather";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import axios from "../config/axios";
import * as yup from "yup";
import Loader from "../components/common/Loader";
import createCategory from "../api/service-category/createCategory";

export default function AddServiceCategory() {


  let submitForm = async (name, specialityImg) => {
    const data = new FormData();

    data.append("name", name);
    // if user uploaded an specialityImg
    if (specialityImg.length > 0) {
      data.append("specialityImg", specialityImg[0], specialityImg[0].name);
    }

    await createCategory(data);
  };

  const schema = yup.object().shape({
    name: yup.string().required("الرجاء إدخال اسم التخصص"),

    specialityImg: yup.array().required("قم بتحميل الشعار"),
  });

  let formik = useFormik({
    initialValues: {
      name: "",
      specialityImg: [],
    },

    validationSchema: schema,

    onSubmit: async ({ name, specialityImg }, { resetForm }) => {
      await submitForm(name, specialityImg);
      resetForm();
    },
  });

  //   console.log(formik.errors)

  return (
    <div>
      <div className="mx-auto w-full md:w-5/6 mt-20 p-5 bg-white rounded border-2 border-gray-300">
        <h2 className="mb-10 text-center text-xl text-gray-700">
          إضافة تخصص خدمة جديد
        </h2>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-10">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              اسم التخصص
            </label>
            <input
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                formik.errors.name ? "border-red-400" : ""
              }`}
              type="text"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              placeholder="مثال: طبيب أطفال"
            />
            {formik.errors.name ? (
              <p className="text-red-400 text-sm">{formik.errors.name}</p>
            ) : null}
          </div>

          <div className="mb-10">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              شعار التخصص
            </label>
            <div
              className={`hover:bg-gray-200 relative h-32 w-32 rounded overflow-hidden mb-5 user-img-edit border-2 border-gray-300 transition cursor-pointer ${
                formik.errors.specialityImg ? "border-red-400" : ""
              }`}
            >
              <Upload
                className="absolute text-gray-600"
                style={{ top: "40%", left: "40%" }}
              />
              <Dropzone
                accept="image/jpeg, image/jpg, image/png"
                multiple={false}
                maxSize={1000000} // 1000000 = 1 MB
                onDrop={(file) => {
                  formik.setFieldValue("specialityImg", file);
                }}
                onDropRejected={(err) => {
                  if (err[0].errors[0].code === "file-too-large") {
                    toast("الصورة كبيرة جدًا ، يجب ألا تتجاوز 2 ميغابايت", {
                      className: "errorToast",
                    });
                  } else {
                    toast(
                      "يرجى تحميل صورة ، يجب أن تكون من نوع jpeg أو png أو jpg",
                      { className: "errorToast" }
                    );
                  }
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} className="h-full block">
                    <input
                      {...getInputProps()}
                      className="w-full bg-gray-300 z-50 relative h-full opacity-0 cursor-pointer"
                      style={{ display: "block" }}
                    />
                  </div>
                )}
              </Dropzone>
            </div>

            {formik.errors.specialityImg ? (
              <p className="text-red-400 text-sm">
                {formik.errors.specialityImg}
              </p>
            ) : null}
          </div>

          {formik.isSubmitting ? (
            <span className="opacity-50 block w-full block bg-blue-500 text-white p-2 rounded shadow mt-16">
              <Loader className="mx-auto" />
            </span>
          ) : (
            <button
              type="submit"
              className="w-full block bg-blue-500 text-white p-2 rounded shadow mt-16"
            >
              أضف التخصص
            </button>
          )}
        </form>
      </div>
    </div>
  );
}
