import React, { useEffect, useState } from 'react';
import axios from '../config/axios';

export default function Dashboard() {

	let [totals, setTotals] = useState({ totalUsers: '-', totalQuestions: '-', totalNewUsers: '-' });

	useEffect(() => {
		(async function(){

			try{
				let res = await axios.get('/');
				console.log(res.data)
				setTotals({
					totalUsers: res.data.total_users,
					totalQuestions: res.data.total_questions,
					totalNewUsers: res.data.new_users,
				});

			}catch(err){
				alert('something went wrong, please try again')
			}

		}());
	}, []);

	return (
		<div>
			<div className="flex flex-col">
				{/* Stats Row Starts Here */}
				<div className="flex flex-1 flex-col md:flex-row lg:flex-row mx-2">
					<a
						href="/users"
						className="shadow bg-blue-600 border-l-8 hover:bg-blue-700 border-blue-800 mb-2 p-2 md:w-1/3 mx-2"
					>
						<div className="p-4 flex flex-col">
							<span className="no-underline text-white text-2xl">
								{totals.totalUsers}
							</span>
							<span className="no-underline text-white text-lg">مجموع المستخدمين</span>
						</div>
					</a>
					<a
						href="/questions"
						className="shadow bg-indigo-600 border-l-8 hover:bg-indigo-700 border-indigo-800 mb-2 p-2 md:w-1/3 mx-2"
					>
						<div className="p-4 flex flex-col">
							<span className="no-underline text-white text-2xl">
								{totals.totalQuestions}
							</span>
							<span className="no-underline text-white text-lg">مجموع الأسئلة</span>
						</div>
					</a>
					<a
						href="/new-users"
						className="shadow inline-block bg-green-600 border-l-8 hover:bg-green-700 border-green-800 mb-2 p-2 md:w-1/3 mx-2"
					>
						<div className="p-4 flex flex-col">
							<span className="no-underline text-white text-2xl">
								{totals.totalNewUsers}
							</span>
							<span className="no-underline text-white text-lg">المستخدمين المسجلين اليوم</span>
						</div>
					</a>
				</div>
				{/* /Stats Row Ends Here */}
			</div>
		</div>
	);
}
