
import axios from '../../config/axios'
import { toast } from 'react-toastify'

export default async function getCouponStats(coupon_id){

   try{

      let resp = await axios.get(`/coupon/stats/${coupon_id}`);

      // console.log(resp);

      return resp.data;

   }catch(err){
      toast('حدث خطأ ما', {className: 'warnToast'})
   }

}

