import React, { useState } from 'react'
import timeAgo from 'nta';
import { Trash} from 'react-feather';
import DeleteQuestionModal from './DeleteQuestionModal';
// import DeleteUserModal from './DeleteUserModal';


export default function QuestionTableRow({ question }) {

   let [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
   // console.log(question)

   return (
      <tr>
         <td class="px-6 py-4 whitespace-wrap border-b border-gray-200">
            {question.title}
         </td>
         <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            {!question.fullname ? 'لوحة التحكم': question.fullname}
         </td>
         <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            {timeAgo(question.created_at)}
         </td>

         <td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">

            <span aria-label='delete-topic' className='inline-block bg-red-100 p-2 cursor-pointer hover:bg-red-200 rounded-full' title='حذف السؤال' onClick={() => setIsDeleteModalOpen(true)}> <Trash size={18} className='text-red-500' /> </span>
            {/* Modals */}
            <DeleteQuestionModal
               question={question}
               isOpen={isDeleteModalOpen}
               closeModal={() => setIsDeleteModalOpen(false)}
            />

         </td>
      </tr>
   )
}
