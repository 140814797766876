import React, { useEffect, useState } from "react";
import axios from "../config/axios";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";

export default function TsaolSettings() {
  const [fees, setFees] = useState({
    loading: true,
    data: 0,
  });

  useEffect(() => {
    (async function () {
      let resp = await axios.get("/tsaol/settings/get-fees");
      setFees({
        loading: false,
        data: resp.data,
      });
    })();
  }, []);

  const formik = useFormik({
    initialValues: {
      fees: '',
    },

    validationSchema: yup.object().shape({
      fees: yup
        .number()
        .required("الرجاء إدخال قيمة الرسوم")
        .max(99, "الحد الأقصى لقيمة الرسوم 99٪")
    }),

    onSubmit: async ({ fees }) => {
      try{

         await axios.post('/tsaol/settings/update-fees', {fees});

         toast('تم تغيير الرسوم بنجاح', {className: 'successToast'})

      }catch(err){
         toast('هناك خطأ ما...', {className: 'warnToast'})
      }
    },
  });

  return (
    <React.Fragment>
      <div className="bg-white px-3 rounded-lg shadow py-10">
        <div className="max-w-lg mx-auto">
          <h2 className="text-center block font-bold text-xl"> رسوم السحب </h2>
          <div className="mt-10">
            <form onSubmit={formik.handleSubmit}>
              <div className="w-full">
                <div className="flex">
                  {fees.loading ? 
                     <input
                        className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${fees.loading ? 'bg-gray-300 opacity-50' : ''}`}
                        name="fees"
                        type="number"
                        min="1"
                        placeholder="أدخل قيمة الرسوم"
                        disabled
                     />
                  : (
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      name="fees"
                      type="number"
                      min="1"
                      defaultValue={fees.data}
                      onChange={formik.handleChange}
                      placeholder="أدخل قيمة الرسوم"
                    />
                  )}
                  <span
                    className="bg-gray-300 text-gray-600 py-2 px-4 rounded-l"
                    style={{ marginRight: "-45px" }}
                  >
                    %
                  </span>
                </div>
                <p className="text-sm text-red-400 mt-1">
                  {formik.errors.fees}
                </p>
              </div>
              {
                 !formik.values.fees || formik.values.fees == fees.data || !formik.isValid ? (
                  <span
                     className="text-center opacity-50 px-5 py-2 rounded-lg text-white bg-blue-500 hover:bg-blue-600 block w-full mx-auto mt-8"
                  >
                     احفظ التغييرات
                  </span>
                 ) : (
                  <button
                     type="submit"
                     className="px-5 py-2 rounded-lg text-white bg-blue-500 hover:bg-blue-600 block w-full mx-auto mt-8"
                  >
                     احفظ التغييرات
                  </button>
                 )
              }
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
