import React from 'react';
import axios from '../../config/axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';

export default function Login() {

   let formik = useFormik({
      initialValues: {
         email: '',
         password: ''
      },

      validateOnBlur: true,
      validationSchema: Yup.object().shape({
         email: Yup.string()
            .required('رجاءا أدخل بريدك الإلكتروني')
            .email('يرجى إدخال البريد الإلكتروني الصحيح')

         , password: Yup.string()
            .required('يرجى إدخال كلمة المرور')
            .max(50, 'كلمة المرور طويلة جدا')
      }),

      onSubmit: async ({email, password}) => {
         try{

            let res = await axios.post('/login', {
               email,
               password
            })

            if(res.status === 200){
               window.location.pathname = '/';
            }else if(res.status === 202){
               formik.setErrors({email: res.data.error})
            }

         }catch(err){
            console.log(err)
            alert('حدث خطأ ما ، يرجى المحاولة لاحقًا')
         }

      }
   })

	return (
		<div className="container mx-auto h-full flex flex-1 justify-center items-center">
			<div className="w-full max-w-lg">
				<div className="leading-loose">
					<form onSubmit={formik.handleSubmit} className="max-w-xl m-4 p-10 bg-white rounded shadow-xl">
						<p className="text-gray-800 font-medium text-center text-lg font-bold">
							تسجيل الدخول إلى لوحة التحكم
						</p>
						<div className>
							<label className="block text-sm text-gray-00">البريد الإلكتروني</label>
							<input
								className={`w-full px-5 border-2 border-gray-600 py-1 text-gray-700 bg-gray-200 rounded ${
                           formik.errors.email ? 'border-red-500' : ''
                        }`}
								name="email"
								type="text"
                        // required
                        onChange={formik.handleChange}
								placeholder="البريد الإلكتروني"
								autoComplete="off"
							/>
                     <p className='text-red-500 text-sm mt-1'>{formik.errors.email}</p>
						</div>
						<div className="mt-2">
							<label className="block text-sm text-gray-600">كلمه السر</label>
							<input
								className={`w-full px-5 border-2 border-gray-600 py-1 text-gray-700 bg-gray-200 rounded ${
                           formik.errors.password ? 'border-red-500' : ''
                        }`}
								name="password"
								type="password"
                        // required
                        onChange={formik.handleChange}
								placeholder="كلمه السر"
								autoComplete="off"
							/>
                     <p className='text-red-500 text-sm mt-1'>{formik.errors.password}</p>
						</div>
						{
                     formik.isValid && formik.values.email !== '' && formik.values.password !== '' && !formik.isSubmitting ? (
                        <button
                           type="submit"
                           className="px-4 py-1 text-white font-light tracking-wider bg-gray-800 rounded w-full mt-5 hover:bg-gray-900 font-semibold"
                        >
                           دخول
                        </button>
                     ) : (
                           <button
                              type="button"
                              className="px-4 py-1 text-white font-light tracking-wider bg-gray-800 rounded w-full mt-5 hover:bg-gray-900 font-semibold cursor-not-allowed opacity-50"
                           >
                              دخول
                           </button>
                     )
                  }
					</form>
				</div>
			</div>
		</div>
	);
}
