import React from 'react';
import { X } from 'react-feather';

export default function Modal({isOpen, onClose, title, isLarge, children}) {

	let closeModal = () => {
		onClose();
	}

	return (
		<div>
			<div class={`modal-wrapper ${isOpen ? 'modal-is-open': ''}`}>
				<div onClick={closeModal} class="overlay close-modal" />
				<div class={`modal modal-centered ${isLarge ? 'modal-lg' : ''}`}>
					<div class="modal-content shadow-lg p-5">
						<div class="border-b p-2 pb-3 pt-0 mb-4">
							<div class="flex justify-between items-center">
								{title}
								<span
									onClick={closeModal}
									class="close-modal transition cursor-pointer p-2 rounded-full bg-gray-200 hover:bg-gray-300"
								>
									<X />
								</span>
							</div>
						</div>

						{/* <!-- Modal content --> */}
                  {children}

					</div>
				</div>
			</div>
		</div>
	);
}
