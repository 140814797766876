import axios from '../../config/axios'
import { toast } from 'react-toastify'

export default async function toggleCoupon(coupon_id, isOff){

   try{

      await axios.post('/coupon/toggle', {coupon_id, isOff});

      let msg;

      if(isOff){
         msg = 'تم إيقاف الكوبون'
      }else{
         msg = 'تم تشغيل الكوبون مرة أخرى'
      }

      toast(msg, {className: 'successToast'});

      // window.location.reload()

   }catch(err){
      toast('هناك شئ خاطئ، يرجى المحاولة فى وقت لاحق', {className: 'warnToast'})
   }

}

