import React, { useState } from "react";
import Modal from "./../common/Modal";
import { useFormik } from "formik";
import * as yup from "yup";
import updateCategory from "../../api/service-category/updateCategory";
import resolveImg from "../../helpers/resolveImg";
import { toast } from "react-toastify";
import { Upload } from "react-feather";
import axios from "../../config/axios";
import Dropzone from "react-dropzone";
import Loader from "../common/Loader";

export default function EditCategoryModal({ category, isOpen, closeModal }) {
  let submitForm = async (name, specialityImg) => {
    const data = new FormData();

    data.append("name", name);
    data.append('category_id', category.id)
    // if user uploaded an specialityImg
    if (specialityImg.length > 0) {
      data.append("specialityImg", specialityImg[0], specialityImg[0].name);
    }

    await updateCategory(data);
  };

  const schema = yup.object().shape({
    name: yup.string().required("الرجاء إدخال اسم التخصص"),
  });

  let formik = useFormik({
    initialValues: {
      name: category.name,
      specialityImg: [],
    },

    validationSchema: schema,

    onSubmit: async ({ name, specialityImg }, { resetForm }) => {
      await submitForm(name, specialityImg);

      // setTimeout(() => {
      //   window.location.reload();
      // }, 3000);
    },
  });

  return (
    <Modal title="تحديث الموضوع" isOpen={isOpen} onClose={closeModal}>
      <form onSubmit={formik.handleSubmit}>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-10">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              اسم التخصص
            </label>
            <input
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                formik.errors.name ? "border-red-400" : ""
              }`}
              type="text"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              placeholder="مثال: طبيب أطفال"
            />
            {formik.errors.name ? (
              <p className="text-red-400 text-sm">{formik.errors.name}</p>
            ) : null}
          </div>

          <div className="mb-10">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              شعار التخصص
            </label>
            <div
              className={`hover:bg-gray-200 relative h-32 w-32 rounded overflow-hidden mb-5 user-img-edit border-2 border-gray-400 transition cursor-pointer ${
                formik.errors.specialityImg ? "border-red-400" : ""
              }`}
            >
              <Upload
                className="absolute text-gray-300 z-20"
                style={{ top: "40%", left: "40%" }}
              />
              <Dropzone
                accept="image/jpeg, image/jpg, image/png"
                multiple={false}
                maxSize={1000000} // 1000000 = 1 MB
                onDrop={(file) => {
                  formik.setFieldValue("specialityImg", file);
                }}
                onDropRejected={(err) => {
                  if (err[0].errors[0].code === "file-too-large") {
                    toast("الصورة كبيرة جدًا ، يجب ألا تتجاوز 2 ميغابايت", {
                      className: "errorToast",
                    });
                  } else {
                    toast(
                      "يرجى تحميل صورة ، يجب أن تكون من نوع jpeg أو png أو jpg",
                      { className: "errorToast" }
                    );
                  }
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} className="h-full block">
                    <input
                      {...getInputProps()}
                      className="w-full bg-gray-300 z-50 relative h-full opacity-0 cursor-pointer"
                      style={{ display: "block" }}
                    />
                    <span role='overlay' className='absolute left-0 top-0 w-full h-full bg-gray-800 opacity-50 z-10'></span>
                    <img
                      className="object-cover h-full block absolute top-0 z-0"
                      src={resolveImg(category.img)}
                    />
                  </div>
                )}
              </Dropzone>
            </div>

            {formik.errors.specialityImg ? (
              <p className="text-red-400 text-sm">
                {formik.errors.specialityImg}
              </p>
            ) : null}
          </div>

          {formik.values.name === category.name && formik.values.specialityImg.length == 0 ? (
            <span className="text-center opacity-50 block w-full block bg-blue-500 text-white p-2 rounded shadow mt-16">
              احفظ التغييرات
            </span>
          ) : formik.isSubmitting ? (
            <span className="opacity-50 block w-full block bg-blue-500 text-white p-2 rounded shadow mt-16">
              <Loader className="mx-auto" />
            </span>
          ) : (
            <button
              type="submit"
              className="w-full block bg-blue-500 text-white p-2 rounded shadow mt-16"
            >
              أضف التخصص
            </button>
          )}
        </form>
      </form>
    </Modal>
  );
}
