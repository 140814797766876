import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from '../../config/axios';
import Cookies from 'js-cookie';
import SuccessMsg from '../common/alerts/Success';

export default function AddUserForm() {

   let formik = useFormik({
      initialValues: {
         fullname: '',
         email: '',
         password: ''
      },

      validateOnBlur: true,

      validationSchema: Yup.object().shape({
         fullname: Yup.string()
            .required('أدخل اسمك الكامل من فضلك')
            .max(50, 'قمت بكتابة اسم طويل جدًا ، حاول أن تجعله أقصر')
            .min(4, 'لقد كتبت اسمًا قصيرًا جدًا ، يرجى كتابته بالكامل ، ولا تقلق يمكنك تعديله لاحقًا إذا أردت')

         , email: Yup.string()
            .email('البريد الإلكتروني غير صالح')
            .required('يرجى كتابة بريدك الإلكتروني')
            .max(255, 'قمت بإدخال بريد إلكتروني طويل جدًا وغير صالح')
         , password: Yup.string()
            .required('أدخل كلمة المرور')
            .min(5, 'يجب أن تحتوي كلمة المرور على 5 أحرف على الأقل')
            .max(25, 'يجب ألا تحتوي كلمة المرور على أكثر من 25 حرفًا')
      }),

      onSubmit: async (values) => {
         try{

            let res = await axios.post('/add-user', {
               ...values
            });

            if(res.status === 200){
               Cookies.set('success', res.data, {
                  expires: new Date(new Date().getTime() + 15 * 1000) // 15s
               });

               window.location.reload();
            }else if(res.status === 202){
               formik.setErrors(res.data)
            }

         }catch(err){
            alert('Something went wrong, please try later')
         }
      }
   })

	return (
		<div className="container mx-auto">
			<div className="mt-10">

            {Cookies.get('success') ? <SuccessMsg body={Cookies.get('success')} /> :  null}

				<div className="w-full md:w-1/2 mx-auto">
					<h1 className="text-center text-2xl mb-5 font-bold"> أضف مستخدم جديد </h1>
					<form onSubmit={formik.handleSubmit}>
						<input
							type="text"
							placeholder="الاسم الكامل"
							name="fullname"
                     size={20}
                     onChange={formik.handleChange}
							className="appearance-none block bg-white text-grey-darker border-2 border-gray-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white-500 focus:border-gray-600 mt-2 w-full"
							defaultValue={formik.values.fullname}
						/>
                  <p className='mt-1 text-red-400 text-sm'>{formik.errors.fullname}</p>
						<input
							type="email"
							placeholder="البريد الإلكتروني"
							name="email"
                     size={20}
                     onChange={formik.handleChange}
							className="appearance-none block bg-white text-grey-darker border-2 border-gray-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white-500 focus:border-gray-600 mt-2 w-full"
                     defaultValue={formik.values.email}
						/>
                  <p className='mt-1 text-red-400 text-sm'>{formik.errors.email}</p>
						<input
							type="password"
							placeholder="كلمة السر"
							name="password"
                     size={20}
                     onChange={formik.handleChange}
                     defaultValue={formik.values.password}
							className="appearance-none block bg-white text-grey-darker border-2 border-gray-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white-500 focus:border-gray-600 mt-2 w-full"
						/>
                  <p className='mt-1 text-red-400 text-sm'>{formik.errors.password}</p>
						<button
							type="submit"
							className="bg-green-500 hover:bg-green-800 text-white font-bold py-2 px-4 rounded mt-5 w-full"
						>
							أضف المستخدم
						</button>
					</form>
				</div>
			</div>
		</div>
	);
}
