import React, { useState } from 'react'
// import timeAgo from 'nta';
import { Edit2, Trash, CheckCircle, XCircle } from 'react-feather';
// import DeleteTopicModal from './DeleteTopicModal';
// import EditCategoryModal from './EditCategoryModal';
import resolveImg from '../../helpers/resolveImg';
import nta from 'nta'
import ApproveModal from './ApproveModal';
import RejectModal from './RejectModal';


export default function ReqstsTableRow({reqst}) {

   let [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
   let [isRejectModalOpen, setIsRejectModalOpen] = useState(false);

   return (
      <tr>
         <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            {reqst.fullname}
         </td>
         <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            {reqst.email}
         </td>
         <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            {
               JSON.parse(reqst.sepciality).map(cat => (
                  <span className='bg-gray-200 text-gray-600 p-2 rounded-full text-sm m-2'>{cat.label}</span>
               ))
            }
         </td>
         <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            <a href={resolveImg(reqst.cv)} className='text-blue-500 font-bold' target='_blank' rel="noopener">فتح السيرة الذاتية</a>
         </td>
         <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            {nta(reqst.created_at)}
         </td>

         <td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
            <span aria-label='edit-topic' className='inline-block bg-green-100 p-2 cursor-pointer hover:bg-green-200 rounded-full ml-3' onClick={() => setIsApproveModalOpen(true)}> <CheckCircle size={18} className='text-green-500' /> </span>

            <span aria-label='edit-topic' className='inline-block bg-red-100 p-2 cursor-pointer hover:bg-red-200 rounded-full ml-3' onClick={() => setIsRejectModalOpen(true)}> <XCircle size={18} className='text-red-500' /> </span>
            
            {/* Modals */}
            <ApproveModal 
               isOpen={isApproveModalOpen}
               closeModal={() => setIsApproveModalOpen(false)}
               consulter={reqst}
               />
            <RejectModal 
               isOpen={isRejectModalOpen}
               closeModal={() => setIsRejectModalOpen(false)}
               consulter={reqst}
            />
            {/* <EditreqstModal
               isOpen={isEditModalOpen}
               closeModal={() => setIsEditModalOpen(false)}
               reqst={reqst}
            /> */}

         </td>
      </tr>
   )
}
